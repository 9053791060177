import React from "react";
import './episodes.css';
import {DefaultPlayer as Video} from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Rap from '../videos/Raps.mp4';
import phone from '../videos/Raps-iphone.mp4';





const Episodes = () => {
    return (

        <div className="e-container">
            
            <text className="titlesss">Episodes</text>

            <div className="episode-list">

                <div className="e-1">
                    <Video 
                    autoplay
                    loop
                    className='vid'
                    >
                        <source src={Rap} type="video/mp4"/>
                    </Video>

                    <div className="words">Rapity Raps____ep.1</div>


                    <Video 
                    autoplay
                    loop
                    className='iphone'
                    >
                        <source src={phone} type="video/mp4"/>
                    </Video>

                </div>



            </div>
        </div>

    )
}

export default Episodes;